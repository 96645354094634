var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"73be33c45acbb95b33e634f6b1c9f40145a14391"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

const Sentry = require('@sentry/nextjs');

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
});
